// базовые подключения
@import "vars";
@import "mixins";
@import "fonts";
@import "settings";

// подключения компонентов страницы
@import "./components/header";
@import "./components/footer";
@import "./components/sidebar";
@import "./components/upper__list";
@import "./components/articles";
@import "./components/calculator";
@import "./components/calculator_quiz";
@import "./components/email_modal";
@import "./components/button";
@import "./components/form_contact";
@import "./components/pulse_call";
@import "./components/contacts";


@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/RobotoCondensed-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/RobotoCondensed-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}



@font-face {
  font-family: 'Roboto Light';
  src: url('fonts/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Regular';
  src: url('fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('fonts/Roboto-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}






@import url(https://fonts.googleapis.com/css?family=Raleway:300);


  

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

}

body {
  font-family: 'Roboto Condensed', sans-serif;
  background: #1E1E1E;
  font-size: 16px;
}

h1 {
  margin: 0;
}



ul {
  list-style: none;
}

a:hover {
  color: #FFAC30;
}

.container {
  margin: 0 auto;
  max-width: 1220px;

}

.wrapper {
  padding: 60px 100px 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.fade-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  z-index: 999;
}


/** Header **/

.rating__container {
  display: flex;

}
.rating__zone {
  position: relative;
  margin-top: -50px;
  overflow: visible;
  background-color: #EF1925;
  color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 26px;
  padding: 0 23px;
  margin-right: 0.9em;
  height: 100px;
}

.rating__zone::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  left: 0;
  bottom: -20px;
  border-top: 21px solid #EF1925;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
}

.rating__main {
  font-size: 26px;
  padding-top: 2em;
}

.rating__block {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rating__stars {
}
.stars__block {
}
.stars {
  height: 12px;
  width: 12px;
}
.stars__text {
}


.header-hero-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-image: url(../img/main_2.jpg);
  animation: zoomHero 120s infinite;


}


/* Ключевые кадры для анимации */
@keyframes zoomHero {
  0% {
    background-size: 100%;
    background-position: center;
}
100% {
    background-size: 130%;
    background-position: 30% center; /* Смещение изображения вправо */
}
}





.burger {
  display: none;
}

.header__social-list {
  display: none;
}






/** Hero **/

.hero__container {
  position: relative;
}


.hero__img {
  position: absolute;
  right: 0;
  width: 35%;
  opacity: 0.2;
}

.hero__block {
  margin-top: 10vh;
  color: white;
  margin-bottom: 2vh;
  max-width: 800px;
}

.hero__subtitle {
  font-weight: 300;
  font-size: 1.3em;
  line-height: 139%;
  opacity: 0.8;
  letter-spacing: 2px;
}

.hero__title {
  font-weight: 300;
  font-size: clamp(28px, 5vw, 48px);
  line-height: 100%;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.2em;
  letter-spacing: 4px;
}

.title-color {
  color: #FFAC30;
}


.hero__discr {
  font-weight: 300;
  font-size: 1.4em;
  line-height: 109.1%;
  letter-spacing: 3px;
  margin-bottom: 4vh;
}

.hero__discr-main {
  font-size: 1em;
  display: inline;
}

.hero__discr-color {
  color: #FFAC30;
}

.hero__link {
  color: #FFF;
  text-transform: uppercase;
  font-size: 1.2em;
  padding: 0.9em 2,2em;
  border-right: 2px solid white;
  border-left: 2px solid white;
  background-color: inherit;
  margin-right: 3em;
  letter-spacing: 2px;
  transition: all 0.3s ease-in-out;
}

.hero__link:hover {
  color: black;
  background-color: white;
}



/** Advantage **/

.advantage {
  padding-top: 75px;
  background-color: #FFFF;
  color: #413b3b;
  padding-bottom: 100px;
  
}

.advantage__upper {
  margin-bottom: 35px;
}
.advantage__upper-block {
  margin-top: -30px;
  position: relative;
  max-width: 60%;
  margin-bottom: 5em;
}
.advantage__upper-text {
  padding-top: 2em;
  font-size: 1.4em;
  font-weight: 300;
line-height: 121%;
margin-right: 5em;

}
.advantage__upper-title {
  font-size: 1.8em;
}


.advantage__container {
  background-image: url(../img/map.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.advantage__block {
  margin-top: 5vh;
  position: relative;
  list-style-type:disc;
  padding-right: 60px;
  width: 80%;
  font-weight: bold;

}




.advantage__block-element {
  font-size: 1.3em;
  font-weight: 300;
  margin-bottom: 30px;

}
.advantage__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  grid-column-gap: 30px; 

}
.advantage__item {
  width: 100%;
height: 249px;
box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.09);
border-radius: 12px;
margin-bottom: 30px;
padding: 30px 30px;
background-color: #FFFF;
transition: all ease-in-out 0.3s;
}


.advantage__item:hover {
  box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.2);
}

.advantage-min {
  font-size: 0.6em;
}





.advantage__item-title {
  font-weight: 800;
font-size: 1.3em;
color: #38647c;
margin-bottom: 0.9em;
}
.advantage__item-sutitle {
  font-weight: 300;
  font-weight: 0.7em;
}
.info__item-title {

}


























/**CEO**/

.ceo {
  padding: 100px 0;
  font-family: "Roboto";
  color: #000;
  background-color: white;
}


.ceo__container {
 display: flex;
 align-items: center;
 padding-bottom: 100px;
}
.ceo__block-left {
  margin: 2em  10em 0px 3em;
  width: 60%;
}
.ceo__logo {
  opacity: 0.2;
  margin-bottom: 10px;
}
.ceo__title {
  margin-bottom: 30px;
font-size: 1.9em;
font-weight: 400;
line-height: 1.3em;
letter-spacing: 0.3px;
}
.ceo__discr {
  text-align: justify;
  font-size: 1em;
font-style: normal;
font-weight: 200;
line-height: normal;
letter-spacing: 0.25px;
margin-bottom: 35px;
}



.ceo__block-picture {
  width: 40%;
}



/**services**/




.services {
  position: relative;
  color: white;
  background: #232323;
  backdrop-filter: blur(5px);
  padding-bottom: 250px;
}

.services__title {
  font-style: normal;
  font-weight: 500;
  font-size: 2.8em;
  margin-bottom: 51px;
}



.services-dectop__contianer {
  display: flex;
  justify-content: space-between;
  outline: none; /* Убираем стандартный контур при фокусе */
  gap: 10px;
  margin-bottom: 10em;
}

.services__desktop-block {
  width: 33%;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  color: #292E31;
  padding: 60px 40px;
  border-radius: 10px;
  position: relative;
  transition: transform 0.5s ease-in-out, opacity 0.5s;
  cursor: pointer; /* Добавляем указатель, чтобы показать, что элементы интерактивны */
}

.services__desktop-block:focus, .services__desktop-block:hover {
  transform: scale(1.05);
  opacity: 1;
  z-index: 2;
}

.services__desktop-block:not(:focus):not(:hover) {
  transform: scale(0.95);
  opacity: 0.9;
}








.services-desktop__upper-block {
  font-weight: 300;
  font-size: 20px;
  padding: 20px 15px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.servies__desktop-list {
  padding: 15px 15px;
  margin-bottom: 30px;
}
.services__desktop-item {
  margin-bottom: 20px;
}
.services__desktop-item-title {
  padding-right: 10px;
  font-size: 1em;
  font-weight: 300;
  line-height: 120%;
}


.services__desktop-item-subtitle {
  font-weight: 300;
  font-size: 0.9em;
  list-style: disc;
  margin: 5px 25px;
  line-height: 120%;
  
}

.services__desktop-lower {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}



.services__decktop-button {
  margin-top: auto;
  position: relative;
  border-radius: 10px;
  color: #FFFF;
  background-color: #FFAC30;
  transition: all ease-in-out 0.3s;
  box-shadow: 0 4px 13.7px rgba(255, 187, 12, 0.25);
  padding: 15px 60px;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 10px;
}

.services__desktop-block:hover .services__decktop-button::before {
  content: '';
  position: absolute;
  top: -100%; /* Увеличиваем начальную точку для лучшего эффекта */
  left: -100%;
  width: 200%;
  height: 200%;
  background: linear-gradient(40deg, transparent 45%, rgba(255, 255, 255, 0.4) 50%, transparent 45%);
  animation: question 2s infinite linear;
}



.services__desktop-discr {
  font-size: 0.9em;
  color: #701414;
}




/** Expertise **/

.expertise {
  background-color: #FFFFFF;
  justify-content: space-between;
  padding-bottom: 8em;
}


.expertise-title {
  font-weight: 500;
  font-size: 3.5em;
  line-height: 100%;
  margin-bottom: 2em;
}

.expertise-subtitle {
  display: inline-block;
  font-weight: 500;
  font-size: 2em;
  margin-bottom: 20px;
  margin-right: 10px;
}


.expertise-info {
  max-width: 60%;
  font-size: 1em;
  margin-bottom: 50px;
}


.expertise-text {
  margin-bottom: 2em;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.5em;
  letter-spacing: -0.055em;
}

.expertise__container {
  position: relative;
  padding: 8em 0;
  z-index: 0;
  overflow: hidden;
}



.swiper-container {
  
}





.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: white;
  width: 10em;
  height: 5px;
  border-radius: 0;
}

.expertise-item img {
  transition: transform 0.5s;
  z-index: 0;
  border: 1px solid rgb(152, 132, 132); 
}

.expertise-item img:hover {
  transform: scale(1.7);
  z-index: 1000;
  position: absolute;
}


/** Blog **/

.blog {
  position: relative;
  padding-top: 32px;
  padding-bottom: 45px;
  background-color: #FFFFFF;
}

.blog__title {
  font-weight: 500;
  font-size: 2.8em;
  line-height: 1.5em;
  letter-spacing: -1px;
  color: #152739;
  margin-bottom: 1em;
}

.blog__title-border {
  border-bottom: 10px solid #5E1010;
}

.blog__main {
  height: 370px;
  background-image: url(../img/blog_main.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  color: #FFFFFF;
  border-radius: 15px;
  margin-bottom: 46px;
}


.blog__main-info {
  max-width: 744px;
  padding: 28px 20px 33px 9em;
}

.blog__main-date {
  font-size: 0.9em;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.7;
  margin-bottom: 49px;
}

.blog__main-title {
  font-weight: 400;
  font-size: 1.8em;
  line-height: 1em;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

.blog__main-subtitle {
  font-size: 1em;
  line-height: 1.2em;
  letter-spacing: -0.2px;
  color: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 3em;
}

.blog__main-button {
  font-size: 1.1em;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.9;
}

.blog__subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #F7F8FA 0%, #EDF1F5 100%);
  margin-bottom: 64px;
  text-align: justify;
}

.blog__subscribe-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 823px;
  height: 248px;
  padding: 50px 0;
  color: #152739;
}

.blog__subscribe-title {
  font-weight: 400;
  font-size: 1.8em;
  line-height: 28px;
  letter-spacing: -0.777778px;
  color: #152739;
  margin-bottom: 16px;
}

.blog__subscrible-subtitle {
  font-size: 1em;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #152739;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 14px;
}



.blog__subscribe-input {
  text-align: center;
  color: #777470;
  width: 250px;
  height: 61px;
  font-size: 1.25em;
  line-height: 23px;
  border: 1px solid rgba(54, 50, 50, 0.7);
  border-radius: 14px;
  margin-right: 28px;
}

.blog__subscribe-submit {
  text-align: center;
  color: #777470;
  width: 250px;
  height: 61px;
  font-size: 1.25em;
  line-height: 23px;
  border: 1px solid rgba(54, 50, 50, 0.7);
  border-radius: 14px;
  background-color: #ffbd5b;
  transition: all .3s ease-in-out;
}

.blog__subscribe-submit:hover {
  border: 1px solid #FFF;
  background-color: #ffa621;
  color: #FFF;
}

.blog__articles-title {
  font-size: 2em;
  line-height: 44px;
  letter-spacing: -1px;
  color: #152739;
  margin-bottom: 20px;
}


.blog__articles-search {
  width: 100%;
  background: #FAFBFC;
  height: 54px;
  padding-left: 50px;
  background: #FAFBFC;
  border: 1px solid #D4D8DF;
  border-radius: 6px;
  margin-bottom: 36px;
}

.blog__articles-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.blog__articles-item {
  margin-bottom: 28px;
}

.blog__articles-picture {
  display: inline-block;
    width: 100%;
    overflow: hidden;
  }
  
  .blog__articles-img {
    display: inline-block;
    height: 15vw;
    width: 100%;
    margin-bottom: 18px;
    border-radius: 10px;
}


.blog__articles-img:hover {
  filter: brightness(0.7);
}

.blog__articles-data {
  display: block;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #152739;
  mix-blend-mode: normal;
  opacity: 0.4;
  margin-bottom: 10px;
}

.blog__articles-title {
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1em;
  letter-spacing: -0.403846px;
  color: #000000;
  margin-bottom: 1.4em;
}

.blog__articles-button {
  font-size: 1.1em;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: #FFAC30;
  mix-blend-mode: normal;
  opacity: 0.9;
  transition: color .2s ease-in-out;
}

.blog__articles-button:hover {
  color:rgb(8, 4, 121);
}


/** FAQ **/




.faq {
  background: #282731;
  backdrop-filter: blur(5px);
  color: white;
  padding-top: 35px;
  padding-bottom: 100px;
}


.faq__title {
  font-size: 2.8em;
  line-height: 2.8em;
  text-transform: capitalize;
  margin-bottom: 2em;
}

.faq__subtitle {
  font-size: 4em;
  line-height: 94.5%;
  letter-spacing: -3.2px;
  margin-bottom: 33px;
}

.faq__discr {
  font-size: 1.1em;
  line-height: 156.5%;
  margin-bottom: 75px;
}

.faq__item {
  margin-bottom: 5px;
}

.faq__item-info {
  visibility: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.faq__item-info.ac-active {
  visibility: visible;
  max-height: 360px;
}


.faq__item-title {
  margin-bottom: 30px;
}

.faq__item-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  padding: 40px 20px;
  border: none;
  border-radius: 8px;
  background: rgba(217, 217, 217, 0.28);
  font-size: 1em;
}

.faq__item-text {
  text-align: left;
  max-width: 75%;
}

.faq__item-btn {
  width: 25px;
  height: 25px;
}


.faq__item-discr {
  min-height: 248px;
  font-size: 1.2em;
  line-height: 156.5%;
  padding: 54px 20px;
  border-radius: 8px;
  background: #181D24;
  margin-bottom: 20px;
}




.faq__questions-block {
  padding: 56px 0 43px 60px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8.5px);
  margin-bottom: 50px;
}

.faq__questions-title {
  font-size: 2.25em;
  line-height: 43px;
  margin-bottom: 8px;
}

.faq__qustions-subtitle {
  color: #FFF;
  font-size: 1.1em;
  line-height: 32px;
  margin-bottom: 24px;
}

.faq__questions-form {
  max-width: 600px;
  padding-right: 45px;
}

.faq__questions-label {}

.faq__questions-input {
  padding: 15px;
  color: white;
  font-size: 1em;
  width: 100%;
  height: 64px;
  border: none;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 0px 4px 41px 0px rgba(0, 0, 0, 0.13);
  backdrop-filter: blur(2px);
  margin-bottom: 16px;
}

.faq__questions-text {
  font-size: 1.1em;
  line-height: 52px;
}

.faq__questions-area {
  padding: 15px;
  color: white;
  font-size: 1em;
  width: 100%;
  border-radius: 14px;
  background: var(--white-white-10, rgba(255, 255, 255, 0.10));
  box-shadow: 0px 4px 41px 0px rgba(0, 0, 0, 0.13);
  backdrop-filter: blur(2px);
  margin-bottom: 48px;
}

.faq__questions-submit {
  border: none;
  color: #534E4E;
  font-size: 1.25em;
  width: 40%;
  height: 63.078px;
  border-radius: 14px;
  background: #FFAC30;
  transition: all 0.2s ease-in-out;
}

.faq__questions-submit:hover {
  background: #fa9c10;
  color: #FFF;
  border: 2px solid #FFF;
}

.faq__questions-submit:focus {
  background: #fa9c10;
  color: #FFF;
  border: 2px solid #FFF;
}

.faq__questions-submit:active {
  background: #fa9c10;
  color: #FFF;
  border: 2px solid #FFF;
}

.faq__slogan {
  color: #FFF;
  text-align: center;
  font-size: 1.6em;
  line-height: 2em;
  text-transform: uppercase;
  padding-left: 40px;
}

/**Partners**/

.partners {
  background: #FFF;
  padding-top: 56px;
  padding-bottom: 67px;
}

.partners__title {
  color: #1E252F;
  text-align: center;
  font-size: 2.8em;
  line-height: 110.5%;
  margin-bottom: 136px;
}

.partners__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


.parners__item {
  width: 25%;
  margin-bottom: 60px;
}

.partners__img {
  margin: 0 auto;

}
/** Calc **/
.calc {
  background-color: #FFF
}

.result {
  width: 300px;
  height: 150px;
  background-color: #5E6B7E;
  color: #ffffff;
}


/**Popup Calculator**/

.hidden {
  display: none;
}
.popup-visible {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#calculator__popup {
  width: 80%;
  height: 50vh;
}



/** About-US **/

.about-us {
  font-family: Roboto;
  background-color: #FFF;
}

.about-us.header {
  background: white;
}
.about-us__hero {
  background-image: url(../img/articles/about-us_2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  color: white;
  text-align: center;
  filter: grayscale(50%);
  z-index: 1;
}
.about-us__hero-title {
  margin: 150px 35px;
  font-size: 4em;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
z-index: 5;
}

.about-us__hero-discr {
  margin: 0 35px;
  font-size: 4em;
font-style: normal;
font-weight: 700;
letter-spacing: -0.374px;
margin-bottom: 40px;
}

.about-us__hero-link {
  font-size: 1em;
font-style: normal;
font-weight: 400;
line-height: 24px;
}

.about-us__video {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us__video-title {
  font-size: 4em;
font-style: normal;
font-weight: 700;
line-height: normal;
  
}
.about-us__main {
  margin-bottom: 100px;
 
}
.about-us__main-title {
  font-size: 2.4em;
font-style: normal;
font-weight: 700;
line-height: 150%; 
letter-spacing: 0.3px;
}
.about-us__main-discr {
  color: #343B40;
font-size: 1.8em;
font-style: normal;
font-weight: 400;
line-height:  159%;
letter-spacing: -0.2px;
margin-bottom: 3em;
}
.about-us__main-upper {
  display: flex;
  justify-content: space-between;
}

.about-us__main-text {
  padding: 50px 80px;
  width: 50%;
}

.about-us__main-picture {
  width: 50%;
  background-image: url(../img/articles/about-us_2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  
}

.main__text-title {
  color: #353434;
font-size: 2.3em;
font-style: normal;
font-weight: 700;
line-height: 150%; 
letter-spacing: 0.3px;
text-transform: uppercase;
}
.main__text-subtitle {
  color: #353434;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 500;
}
.main__text-list {
  list-style: disc;
  list-style-position: inside;
  margin-bottom: 2em;
}
.main__text-item {
  font-size: 1.4em;
  font-style: normal;
  font-weight: 500;
  line-height: 200%; 
  letter-spacing: -0.2px;
}
.about-us__main-lower {
  display: flex;
  justify-content: space-between;
}
.about-us__main-img {
  width: 100%;
}
.about-us__services {
}
.about-us__services-title {
  text-align: center;
  font-size: 2em;
font-style: normal;
font-weight: 700;
line-height: 180%;
margin-bottom: 2em;
}
.about-us__services-list {
margin-bottom: 4em;
list-style: disc;
}


.about-us__services-item {
  font-size: 1.3em;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
  letter-spacing: -0.2px;
}
.about-us__services-discr {
  font-size: 1.4em;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
  letter-spacing: -0.2px;
  margin-bottom: 100px;
}

.grecaptcha-badge { visibility: hidden; }


@import "media";